.inplant-maintenance-fe {
  @import '~balloon-css/src/balloon.scss';

  @import '~@fullcalendar/common/main.css';
  @import '~@fullcalendar/daygrid/main.css';


  label em {
    color: red;
  }

  .th-label {
    width: 200px;
  }

  .table-fz-sm {
    font-size: 0.9rem;
  }

  .badge--status {
    font-size: 85%;
    font-weight: 500;
  }

  .btn-light,
  .btn-gray {
    color: var(--white);
    background-color: var(--gray-400);
    border-color: var(--gray-400);

    &:hover {
      background-color: var(--gray-500) !important;
    }
  }

  .react-datepicker-wrapper,
  .react-bootstrap-daterangepicker-container {
    display: block;
    width: 100%;
  }

  .btn-actions {
    background-color: transparent;
  }

  .data-tooltips {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 4px 6px 0 6px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
      z-index: 100;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &::after {
      content: attr(data-title);
      position: absolute;
      left: 50%;
      top: -6px;
      transform: translateX(-50%) translateY(-100%);
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      color: #fff;
      padding: 4px 2px;
      font-size: 12px;
      min-width: 100px;
      border-radius: 5px;
      pointer-events: none;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }


  // AcknowledgeComponent
  .acknowledge {
    color: lightgray;

    &.acknowledge-active {
      cursor: default;
      color: green;
    }

    &.acknowledge-hoverable {
      cursor: pointer;
      &:hover {
        color: green;
      }
    }
  }

  // Calendar Component
  .calendar-component {
    .fc {
      .fc-header-toolbar h2 {
        font-size: 1.5rem;
      }

      .fc-prev-button .fa.fa-chevron-left::after {
        content: '<';
        display: inline-block;
      }

      .fc-next-button .fa.fa-chevron-right::after {
        content: '>';
        display: inline-block;
      }

      .fc-toolbar {
        button {
          padding: 3px 1.25rem;
        }

        .btn-primary {
          &,
          &:active {
            color: var(--gray-400);
            background-color: transparent;
            border-color: var(--gray-400);
          }

          &:focus,
          &:focus:active {
            box-shadow: none;
          }

          &:not(:disabled):not(.disabled).active,
          &:hover {
            color: var(--brand-primary);
            background-color: transparent;
            border-color: var(--gray-400);

            &:focus,
            &:focus:active {
              box-shadow: none;
            }
          }
        }
      }

      .fc-title {
        font-weight: 100 !important;
        color: #fff;
      }

      .fc-day-grid-event {
        padding: 0.3rem;
      }

      .fc-day-number {
        color: var(--gray-800);
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        font-size: 0.9rem;
      }

      .table-bordered thead th {
        padding: 0.7rem 0;
        background-color: var(--gray-100);
      }

      .alert-info {
        background-color: var(--gray-100);
      }

      .fc-event:hover {
        opacity: 0.8;
      }
    }

    .tooltip {
      opacity: 1;
      z-index: 100;
    }

    .fc-popover {
      position: absolute;
      padding: 0;
    }

    .fc-popover {
      .fc-header .fc-title {
        color: #000 !important;
      }

      .fc-event .fc-title {
        color: #fff !important;
      }

      .fc-body {
        padding: 0;
      }
    }
  }

  // ContainerComponent
  .alert-internal-error {
    border-left: 5px solid var(--warning);
  }


  // CostsPlantTreeViewComponent
  .CostsPlantTreeViewComponent {
    list-style: none;
    padding-left: 0;
    overflow-y: auto;

    ul {
      list-style: none;
      padding-left: 8px;
      margin-bottom: 4px;
    }

    & > ul {
      padding-left: 0;
    }

    li {
      position: relative;
      padding: 0 7px;
      border-radius: 3px;
      cursor: pointer;

      &:not(.has-childres) {
        padding-left: 24px;
      }

      &.opened {
        & > ul {
          max-height: 999em;
        }

        & > span > svg {
          transform: rotateZ(90deg);
        }
      }

      &.active {
        background-color: #fee;
      }

      & > span.icon {
        width: 18px;
        display: inline-block;
        text-align: center;
        cursor: pointer;

        & > svg {
          transition: transform 0.2s;
        }
      }
    }

    li:not(.has-childrens) span.icon {
      line-height: 12px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;

      svg {
        width: 5px;
        height: 5px;
        opacity: 0.7;
      }
    }

    li .name {
      padding: 3px 5px 2px;
      border-radius: 3px;
    }

    li.selected > .name {
      background-color: #fcc;
    }
  }

  // Costs Tree View
  .costs-tree-view {
    .cost-tree-date-picker .react-bootstrap-daterangepicker-container {
      width: 100%;
    }
  }

  // Legend
  .legend {
    display: flex;
    flex-direction: row;
  }

  .legend__item {
    font-size: 0.9rem;

    &:not(:first-child) {
      padding-left: 0.8rem;
    }
  }

  .legend__icon {
    width: 1rem;
    margin-right: 0.25rem;
    text-align: center;
  }

  // Multiselect
  @mixin truncated-text {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dropdown {
    --border-color: #ccc;
    --hover-color: var(--gray-100);

    position: relative;
    font-size: 0.9rem;
  }

  .dropdown-heading {
    @include truncated-text;

    position: relative;
    width: 100%;
    background-color: white;
    padding: 0.25rem 2rem 0.25rem 0.75rem;
    border: 1px solid var(--border-color);
    cursor: pointer;

    &.arrow::after {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      transform: translateY(-50%);
      display: inline-block;
      width: 0;
      height: 0;
      content: '';
      border-right: 0.3em solid transparent;
      border-left: 0.3em solid transparent;
      border-top: 0.3em solid #999;
      border-bottom: 0;
    }

    &.arrow-reverse::after {
      border-bottom: 0.3em solid #999;
      border-top: 0;
    }

    .badge {
      margin-right: 0.5rem;
    }

    > span {
      display: inline-block;
    }
  }

  .dropdown-panel {
    position: absolute;
    top: 100%;
    background-color: white;
    z-index: 999;
    width: 100%;
    max-height: 100px;
    overflow-y: scroll;
    border: 1px solid var(--border-color);
    border-top: 0;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      label {
        @include truncated-text;

        cursor: pointer;
        padding: 0.25rem 0.75rem;
        margin: 0;

        &:hover {
          background-color: var(--hover-color);
        }
      }

      input {
        margin-right: 0.5rem;
      }
    }
  }

  // Plan
  .operation-component {
    font-size: 0.75rem;
  }

  .modal-content {
    .form-check {
      padding-left: 0;

      input {
        margin-right: 8px;
      }
    }
  }

  // RelatedJobs
  .related-jobs {
    .card-header {
      color: var(--dark);
    }

    .infos {
      display: flex;
      flex-direction: row;

      &--highlighted {
        color: var(--brand-primary);
      }

      &__indicator {
        width: 25px;
      }
      &__status {
        width: 150px;
      }
      &__date {
        width: 100px;
      }
      &__user {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
      &__notes {
        flex: 1;
      }
    }

    .card-header[data-toggle='collapse'] {
      &:hover {
        text-decoration: none;
      }

      .svg-inline--fa {
        margin-right: 0.5em;
      }

      &[aria-expanded='true'] {
        .svg-inline--fa {
          transform: rotate(90deg);
        }
      }
    }
  }

  // scheduled-job-add-modal
  .scheduled-job-add-modal {
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-bootstrap-daterangepicker-container {
      display: block;
      width: 100%;
    }
  }

  .clearable-input {
    display: flex;
    align-items: flex-end;
    &>.form-group:first-child {
      width: 100%;
    }
  }

  // scheduled job filters component
  @media (min-width: 992px) {
    .filter-checkbox {
      width: calc(50% - 1rem);
    }
  }

  .card-header[data-toggle='collapse'] {
    &:hover {
      text-decoration: none;
    }

    .svg-inline--fa {
      margin-right: 0.5em;
    }

    &[aria-expanded='true'] {
      .svg-inline--fa {
        transform: rotate(90deg);
      }
    }
  }

  // Target Component
  .target-scheduled-jobs,
  .target-jobs {
    .th-operation {
      width: 50%;
    }

    .th-type {
      width: 10%;
    }

    .th-operator {
      width: 10%;
    }

    .th-status {
      width: 10%;
    }

    .th-confirmed {
      width: 10%;
    }

    .th-jobtype {
      width: 10%;
    }

    .th-date {
      width: 10%;
    }

    .th-notes {
      width: 10%;
    }

    .td-highlighted {
      color: var(--brand-primary);
    }

    .noData {
      text-align: center;
      padding-top: 2rem;
    }
  }

  .card-header--pagination {
    float: right;
    display: flex;
    align-items: center;
  }

  .pagination-arrow {
    position: relative;
    display: inline-block;
    margin-left: 1em;
    padding: 0.75em;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      display: inline-block;
      width: 0;
      height: 0;
      content: '';
      border: 0.5em solid transparent;
    }

    &:not(.pagination-arrow--disable):hover {
      cursor: pointer;

      &::after {
        color: var(--gray-dark);
      }
    }
  }

  .pagination-arrow--disable {
    cursor: auto;

    &::after {
      color: var(--gray);
    }
  }

  .pagination-arrow--left {
    &::after {
      border-right-color: currentColor;
      border-left: 0;
    }
  }

  .pagination-arrow--right {
    &::after {
      border-left-color: currentColor;
      border-right: 0;
    }
  }

  // TaskFormModal
  @media screen and (max-width: 746px) {
    .modal-dialog {
      max-width: 100%;
      margin: 0;

      .modal-content {
        border: none;
        border-radius: unset;
      }
    }
  }

  .modal-dialog {
    max-width: 60vw;

    .fieldset:nth-child(n + 2) {
      border-top: 1px solid #008c7f;
      padding-top: 1rem;
      margin-top: 0.5rem;
    }
  }

  .btn {
    text-transform: uppercase;
    font-size: 0.9em;
  }

  .form-group {
    label:not(.form-check-label) {
      font-weight: bold;
      font-size: 0.9em;
    }
  }






}
