html,
body {
  height: 100%;
}
.inplant-smartdocs {

  &.isMobileWebview {
    .card-details {
      pointer-events: none;
    }
  }

  .WidgetSmartdoc {
    display: flex;
    height: 100%;
    flex: 1;

    .breadcrumb {
      padding: 0.15rem 1rem;

      & > span + span::before {
        content: ' > ';
        display: inline-block;
        padding: 0 8px;
        color: #aaa;
      }

      span {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .cards {
      min-height: 100px;
    }

    .ReactTable a,
    table a {
      cursor: pointer;
    }

    .column {
      float: left;
      overflow-x: hidden;
      min-height: 0;
      flex-shrink: 0;
      flex-grow: 1;
      height: 100%;
      transition: opacity 0.3s, width 0.3s;

      &.to-hide {
        width: 0;
      }
    }

    .wrapper {
      height: 100%;
      overflow: hidden;
      display: flex;
      min-height: 0;
      flex-direction: row;
      flex: 1;
      justify-content: flex-end;
    }

    .wrapper,
    .innerWrapper {
      transition: all 0.3s;
    }
    .innerWrapper {
      display: flex;
    }

    .card-details h4 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-data h4 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .column-level {
    display: inline-flex;
    width: 150px;
    height: 84vh;
    overflow: hidden;
    transition: width 0.3s;
    min-height: 0;
  }

  .column-level.active {
    width: 550px;
  }

  .column-level-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 0;
  }

  .column-level-inner-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 20px;
    margin-right: -20px;
    margin-left: auto;
  }

  .card-level {
    width: 100%;
    min-height: 270px;
    transition: all 0.3s;

    .node-has-qrcode {
      position: absolute;
      top: 45px;
      right: 105px;
      color: #555;
      font-size: 1.4rem;
      margin-top: -0.7rem;

      &:hover {
        color: #212529;
      }
    }
  }

  .card-level.active {
  }

  .card-level.selected {
    background-color: #eafaea;
  }

  .card-level.root-node {
    background-color: #eee3da;
  }

  .column-level.active ~ .column-level {
    opacity: 0;
    visibility: hidden;
  }

  .card-level {
    position: relative;
  }

  .card-level .prev {
    width: 30px;
    height: 100%;
    text-align: center;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .card-level .next {
    width: 30px;
    height: 100%;
    text-align: center;
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .card-level .next {
    display: flex;
    justify-content: center;
  }

  .card-level .next span {
    margin: auto;
  }

  .card-level .next svg {
    width: 10px;
  }

  .card-level .prev svg {
    width: 10px;
  }

  .card-level {
    border: 1px solid #ddd;
  }

  .card-inner {
    padding: 15px 35px;
  }

  .card-level .next svg {
    opacity: 0.3;
    transition: opacity 0.3s, transform 0.3s;
  }

  .card-level .prev svg {
    opacity: 0.3;
    transition: opacity 0.3s, transform 0.3s;
  }

  .column-level:not(:nth-child(1)) .card-level .prev svg {
    opacity: 0.3;
  }

  .card-level .next:hover svg {
    opacity: 1;
    transform: translateX(5px);
  }

  .card-level.active .prev:hover svg {
    opacity: 1;
    transform: translateX(-5px);
  }

  .card-level .next path,
  .card-level .prev path {
    stroke-width: 3px;
  }

  .card-actions {
    margin-top: 25px;
    margin-bottom: 0;
    padding-top: 15px;
    align-self: flex-end;
  }

  .card-inner {
    display: flex;
    width: 100%;
    min-height: 80px;
    flex-direction: column;
    /* flex-grow: 1; */
  }

  .card-level {
    display: flex;
    flex-direction: column;
  }

  .card-details {
    flex-grow: 1;

    .period + .totalHours::before {
      content: ' / ';
    }

    .value {
      float: right;
    }
  }

  .card-inner .list-group-item {
    padding: 0.2rem 0.5rem;
    background-color: transparent;
    font-size: 0.8rem;
  }

  .card-inner .list-group {
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
  }

  .card-inner span.label {
    font-weight: bold;
    display: inline-block;
    width: 30%;
  }

  .card-level {
    border: 1px solid #ccc !important;
    margin-bottom: 8px;
    border-radius: 3px;
    background: rgb(221, 221, 221);
  }

  .card-actions a + a {
    margin-left: 10px;
  }

  .card-inner h4 {
    padding-bottom: 5px;
    font-size: 1.1rem;
  }

  .column-level .column-level-inner-wrapper {
    opacity: 0.5;
    pointer-events: none;
  }

  .column-level .card-title,
  .column-level .card-details,
  .column-level .card-actions {
    display: none;
  }

  .column-level.active .column-level-inner-wrapper {
    opacity: 1;
    pointer-events: auto;
  }

  .column-level.active .card-title,
  .column-level.active .card-details,
  .column-level.active .card-actions {
    display: flex;
  }

  .column-level-wrapper .column-title {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-bottom: 20px;
    margin-left: 33px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .column-level.active .column-level-wrapper .column-title {
    font-size: 1.4rem;
    line-height: 1.4rem;
    opacity: 1;
  }

  .card-level.active,
  .card-level:hover {
    background: #e9ecef;
  }

  .column-level .prev,
  .column-level .next {
    opacity: 0;
  }

  .column-level.active .prev,
  .column-level.active .next {
    opacity: 0.5;
  }

  .column-level .column-level-wrapper h3::after {
    content: '>';
    display: inline-block;
    width: 20px;
    height: 20px;
    float: right;
    margin-right: 10px;
  }

  .column-level.active .column-level-wrapper h3::after {
    display: none;
  }

  .column-level.active .card-level:hover {
    box-shadow: 0 2px 13px rgba(0, 0, 0, 0.2);
  }

  .WidgetTreeNavigation {
    flex-direction: column;
  }

  .WidgetTreeNavigation .column-wrapper {
    flex-direction: row;
  }

  .WidgetTreeNavigation .breadcrumb {
    width: 100%;
    display: inline-flex;
    height: 24px;
    line-height: 24px;
    margin: 15px 5px 30px;
  }

  .breadcrumb .active + .active::before {
    content: '>';
    display: inline-block;
    width: 40px;
    height: 14px;
    text-align: center;
  }

  .breadcrumb .active {
    cursor: pointer;
  }

  .column-wrapper {
    overflow-x: hidden;
  }

  .card-data {
    opacity: 0;
    transition: opacity 0.3s linear 0.3s, height 0.3s, max-height 0.3s ease-out;
    max-height: 0;
    width: 100%;
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding-left: 15px;
  }

  .card-data.active {
    max-height: 900px;
    opacity: 1;
  }

  .card-data.active.data-maintenance {
    height: 350px;
  }

  .card-data.active .card-data-inner {
    opacity: 1;
  }

  .card-data.active.loading .card-data-inner {
    opacity: 0 !important;
  }

  .card-data-inner {
    padding: 15px;

    .meta {
      font-size: 0.8rem;
      margin-top: 5px;
    }
  }

  .card-data-inner {
    transition: opacity 0.3s;
  }

  .card-data-inner a {
    color: #555;
  }

  .card-data-inner .small-data {
    font-size: 0.85rem;
  }

  .hidden {
    display: none;
  }

  .card-data .rt-td,
  .card-data td {
    line-height: 1;
    font-size: 0.9rem;
  }

  .table-sm th,
  .table-sm td {
    padding: 0.5rem 0.3rem;
  }

  .card-data a {
    font-weight: bold;
  }

  .card-actions a {
    width: 40px;
  }

  .card-level:last-child {
    margin-bottom: 140px;
  }

  .card-title {
    p {
      margin-bottom: 0 !important;
      font-size: 0.85rem;
    }
  }

  .node-has-qrcode__absolute {
    display: none;
  }

  .node-has-qrcode__inline {
    margin-top: 5px;
  }

  .row.card-actions {
    width: 100%;
    margin: 0 !important;

    .row-actions__right {
      display: inline-flex;
      justify-content: flex-end !important;
    }

    .row-actions__left {
      display: inline-flex;
      justify-content: flex-start;

      svg {
        margin: 7px 0 0 -15px;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
      }
    }
  }

  .container-fluid.column-level-inner-scroller {
    height: auto !important;
  }

  @media screen and (max-width: 1024px) {
    /* MOBILE TEST */
    .tree-container {
      width: 100%;
    }

    .row.card-details {
      padding: 25px 30px 15px;
    }

    .column-level,
    .column-level-wrapper,
    .column-level-inner-wrapper {
      overflow: visible;
    }

    .column-level.active {
      max-width: 100%;
    }

    .column-level-wrapper .column-title {
      margin-left: 0;
      display: none;
    }

    .column-level-inner-wrapper {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;

      & > .container-fluid {
        height: auto !important;
        padding-left: 15px;
        padding-right: 0;
      }
    }

    .card-title {
      p {
        margin-bottom: 0;
      }
    }

    .card-inner {
      padding: 10px;

      h4 {
        font-size: 1.1rem;
      }
    }

    .card-actions {
      justify-content: space-between;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 3px;
      }
    }

    .breadcrumb {
      padding: 0.15rem 0rem;

      & > span + span::before {
        content: ' > ';
        display: inline-block;
        padding: 0 8px;
        color: #aaa;
      }

      span {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .card-actions > div {
      text-align: center !important;
    }

    .card-level {
      .prev {
        width: 18px;
      }

      .next {
        width: 38px;
      }

      &.data-open {
        .next,
        .prev {
          height: 110px;
        }
      }

      .node-has-qrcode {
        top: auto;
        right: auto;
        left: 40px;
        bottom: 13px;
      }
    }

    .WidgetSmartdoc .breadcrumb span {
      width: 100%;
      padding: 2px 0;

      & + span {
        border-top: 1px solid #aaa;

        &::before {
          display: none;
        }
      }
    }

    .card-title .svg-inline--fa {
      display: none !important;
    }

    .WidgetSmartdoc {
      .wrapper,
      .cards {
        width: 100%;
      }

      .innerWrapper {
        justify-content: flex-end;
      }
    }

    .card-level .prev {
      display: flex;
      justify-content: center;
      width: 40px;
    }
    .card-level .prev span {
      margin: auto;
    }
    .card-title {
      padding-left: 30px;
      margin-bottom: 0 !important;
    }
    .card-actions {
      margin-top: 5px !important;
      margin-right: 30px !important;
    }

    .card-inner h4 {
      margin-bottom: 0 !important;
    }

    .row.card-actions {
      padding-top: 15px;

      .row-actions__left {
        svg {
          margin: 0 0 0 15px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .card-actions {
      align-self: flex-end;
      margin-right: 10px;
    }

    .tooltips {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 4px 6px 0 6px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
        z-index: 100;
        transition: opacity 0.3s;
        opacity: 0;
      }

      &::after {
        content: attr(data-title);
        position: absolute;
        left: 50%;
        top: -6px;
        transform: translateX(-50%) translateY(-100%);
        background: rgba(0, 0, 0, 0.7);
        text-align: center;
        color: #fff;
        padding: 4px 2px;
        font-size: 12px;
        min-width: 100px;
        border-radius: 5px;
        pointer-events: none;
        transition: opacity 0.3s;
        opacity: 0;
      }

      &:hover {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }

    .breadcrumb {
      padding-left: 0;
      padding-right: 0;
      font-size: 0.9em;
      background-color: transparent;
      margin-left: 15px;
      margin-top: 5px;
    }

    .breadcrumb span {
      color: #e94e1b;
      text-decoration: none;
      background-color: transparent;
    }

    .breadcrumb span:last-child {
      color: #6c757d;
    }

    .WidgetSmartdoc .breadcrumb > span + span::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: '/';
    }

    .main-title {
      margin-left: 30px;
      margin-top: 15px;
    }

    .main-title h1 {
      font-size: 1.5rem;
      letter-spacing: -0.025em;
      margin: 0;
      font-weight: 500;
      line-height: 1.2;
      color: inherit;
    }

    .card-level .prev span {
      margin: auto;
    }

    .card-level .prev {
      display: flex;
      justify-content: center;
    }

    .card-level .next {
      width: 50px;
    }

    .WidgetSmartdoc .column {
      max-width: 85px;
    }
    .WidgetSmartdoc .column:last-child {
      width: 500px;
      max-width: 100%;
    }
    .column-level-inner-wrapper {
      overflow-x: hidden;
    }

    .WidgetSmartdoc .column {
      position: relative;

      &:first-child {
        margin-left: 10px;
      }
    }

    .WidgetSmartdoc .wrapper {
      justify-content: flex-end;
    }

    .WidgetSmartdoc .column::after {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 20px);
      height: 100%;
      background-color: #dddddd;
      content: '';
      display: block;
      margin-left: 20px;
      z-index: 100;
      border-radius: 3px;
      border: 1px solid #ccc;
    }

    .WidgetSmartdoc .column:last-child::after {
      display: none;
    }

    .column .column-title {
      transform: rotate(90deg);
      opacity: 1;
      margin: 0;
      position: absolute;
      top: 30px;
      left: 65px;
      width: 250px;
      transform-origin: top left;
      z-index: 200;
      cursor: pointer;
    }

    .column:last-child .column-title {
      display: none;
    }

    .WidgetSmartdoc .wrapper,
    .WidgetSmartdoc .innerWrapper {
      min-width: 48vw;
    }

    .sidecontent {
      width: 45vw;
      float: left;
      margin-top: 82px;
      padding-left: 30px;
      padding-right: 30px;
      border-left: 1px solid #eee;

      .vertical-wrapper {
        max-height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .card-data.active {
      max-height: 0;
      opacity: 0;
    }

    .WidgetSmartdoc .innerWrapper {
      flex-grow: 1;
      flex-shrink: 0;
      width: auto !important;
    }

    .WidgetSmartdoc .wrapper {
      justify-content: flex-end;
    }

    .innerWrapper.animating .column:last-child {
      width: 0;
    }

    .column:last-child .column-title {
      display: block;
      transform: none;
      position: relative;
      top: auto;
      left: 35px;
      font-size: 1.7rem;
      text-transform: none;
      padding-bottom: 20px;
      margin-bottom: 25px;
      border-bottom: 1px solid #eee;
      width: calc(100% - 50px);
      text-align: left;
    }

    .col-1-0 {
      margin-left: 0 !important;
    }

    .sidecontent h3 {
      margin: 0;
      text-align: left;
      font-size: 1.5rem;
      margin-top: 15px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }
    .sidecontent h4 {
      margin-top: 15px;
      font-size: 1.3rem;
    }

    .cards.has-media .card-title {
      cursor: pointer;
      flex-shrink: 0;
    }

    .cards.no-media {
      .card-details,
      .card-actions {
        display: none;
      }
    }

    .cards.no-media .card-title {
      margin-bottom: 0;

      h4 {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .WidgetSmartdoc .cards,
    .card-inner {
      min-height: 60px;
    }

    .WidgetSmartdoc .cards.is-collapsed {
      max-height: 100px;
      overflow-y: hidden;

      .card-details,
      .card-actions {
        opacity: 0;
      }
    }

    .card-title .svg-inline--fa {
      position: absolute;
      top: 23px;
      right: 50px;
      color: #444;
      transition: transform 0.3s;
    }

    /*
    .cards.has-media .card-title .svg-inline--fa {
      transform: rotate(0);
    }
    .cards.has-media.is-collapsed .card-title .svg-inline--fa {
      transform: rotate(-90deg);
    } */

    .inplant-smartdocs .card-title .svg-inline--fa {
      right: 50px;
    }
    .inplant-smartdocs .card-level .next,
    .inplant-smartdocs .card-level .prev {
      width: 50px;
    }
    .inplant-smartdocs .cards.no-media .card-title {
      padding-left: 20px;
    }
  }

  @media screen and (min-width: 1440px) {
    .WidgetSmartdoc .cards {
      max-width: 430px;
    }

    button[data-balloon] {
      overflow: visible;
    }

    [data-balloon] {
      position: relative;
      cursor: pointer;
    }
    [data-balloon]:after {
      filter: alpha(opacity=0);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: all 0.18s ease-out 0.18s;
      -moz-transition: all 0.18s ease-out 0.18s;
      -ms-transition: all 0.18s ease-out 0.18s;
      -o-transition: all 0.18s ease-out 0.18s;
      transition: all 0.18s ease-out 0.18s;
      font-family: sans-serif !important;
      font-weight: normal !important;
      font-style: normal !important;
      text-shadow: none !important;
      font-size: 12px !important;
      background: rgba(17, 17, 17, 0.9);
      border-radius: 4px;
      color: #fff;
      content: attr(data-balloon);
      padding: 0.5em 1em;
      position: absolute;
      white-space: nowrap;
      z-index: 10;
    }
    [data-balloon]:before {
      background: no-repeat
        url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
      background-size: 100% auto;
      width: 18px;
      height: 6px;
      filter: alpha(opacity=0);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: all 0.18s ease-out 0.18s;
      -moz-transition: all 0.18s ease-out 0.18s;
      -ms-transition: all 0.18s ease-out 0.18s;
      -o-transition: all 0.18s ease-out 0.18s;
      transition: all 0.18s ease-out 0.18s;
      content: '';
      position: absolute;
      z-index: 10;
    }
    [data-balloon]:hover:before,
    [data-balloon]:hover:after,
    [data-balloon][data-balloon-visible]:before,
    [data-balloon][data-balloon-visible]:after {
      filter: alpha(opacity=100);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      -moz-opacity: 1;
      -khtml-opacity: 1;
      opacity: 1;
      pointer-events: auto;
    }
    [data-balloon].font-awesome:after {
      font-family: FontAwesome;
    }
    [data-balloon][data-balloon-break]:after {
      white-space: pre-line;
    }
    [data-balloon][data-balloon-blunt]:before,
    [data-balloon][data-balloon-blunt]:after {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
    [data-balloon][data-balloon-pos='up']:after {
      bottom: 100%;
      left: 50%;
      margin-bottom: 11px;
      -webkit-transform: translate(-50%, 10px);
      -moz-transform: translate(-50%, 10px);
      -ms-transform: translate(-50%, 10px);
      transform: translate(-50%, 10px);
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
    [data-balloon][data-balloon-pos='up']:before {
      bottom: 100%;
      left: 50%;
      margin-bottom: 5px;
      -webkit-transform: translate(-50%, 10px);
      -moz-transform: translate(-50%, 10px);
      -ms-transform: translate(-50%, 10px);
      transform: translate(-50%, 10px);
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
    [data-balloon][data-balloon-pos='up']:hover:after,
    [data-balloon][data-balloon-pos='up'][data-balloon-visible]:after {
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
    [data-balloon][data-balloon-pos='up']:hover:before,
    [data-balloon][data-balloon-pos='up'][data-balloon-visible]:before {
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
    [data-balloon][data-balloon-pos='up-left']:after {
      bottom: 100%;
      left: 0;
      margin-bottom: 11px;
      -webkit-transform: translate(0, 10px);
      -moz-transform: translate(0, 10px);
      -ms-transform: translate(0, 10px);
      transform: translate(0, 10px);
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
    [data-balloon][data-balloon-pos='up-left']:before {
      bottom: 100%;
      left: 5px;
      margin-bottom: 5px;
      -webkit-transform: translate(0, 10px);
      -moz-transform: translate(0, 10px);
      -ms-transform: translate(0, 10px);
      transform: translate(0, 10px);
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
    [data-balloon][data-balloon-pos='up-left']:hover:after,
    [data-balloon][data-balloon-pos='up-left'][data-balloon-visible]:after {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='up-left']:hover:before,
    [data-balloon][data-balloon-pos='up-left'][data-balloon-visible]:before {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='up-right']:after {
      bottom: 100%;
      right: 0;
      margin-bottom: 11px;
      -webkit-transform: translate(0, 10px);
      -moz-transform: translate(0, 10px);
      -ms-transform: translate(0, 10px);
      transform: translate(0, 10px);
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
    [data-balloon][data-balloon-pos='up-right']:before {
      bottom: 100%;
      right: 5px;
      margin-bottom: 5px;
      -webkit-transform: translate(0, 10px);
      -moz-transform: translate(0, 10px);
      -ms-transform: translate(0, 10px);
      transform: translate(0, 10px);
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
    [data-balloon][data-balloon-pos='up-right']:hover:after,
    [data-balloon][data-balloon-pos='up-right'][data-balloon-visible]:after {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='up-right']:hover:before,
    [data-balloon][data-balloon-pos='up-right'][data-balloon-visible]:before {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='down']:after {
      left: 50%;
      margin-top: 11px;
      top: 100%;
      -webkit-transform: translate(-50%, -10px);
      -moz-transform: translate(-50%, -10px);
      -ms-transform: translate(-50%, -10px);
      transform: translate(-50%, -10px);
    }
    [data-balloon][data-balloon-pos='down']:before {
      background: no-repeat
        url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
      background-size: 100% auto;
      width: 18px;
      height: 6px;
      left: 50%;
      margin-top: 5px;
      top: 100%;
      -webkit-transform: translate(-50%, -10px);
      -moz-transform: translate(-50%, -10px);
      -ms-transform: translate(-50%, -10px);
      transform: translate(-50%, -10px);
    }
    [data-balloon][data-balloon-pos='down']:hover:after,
    [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
    [data-balloon][data-balloon-pos='down']:hover:before,
    [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
    [data-balloon][data-balloon-pos='down-left']:after {
      left: 0;
      margin-top: 11px;
      top: 100%;
      -webkit-transform: translate(0, -10px);
      -moz-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      transform: translate(0, -10px);
    }
    [data-balloon][data-balloon-pos='down-left']:before {
      background: no-repeat
        url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
      background-size: 100% auto;
      width: 18px;
      height: 6px;
      left: 5px;
      margin-top: 5px;
      top: 100%;
      -webkit-transform: translate(0, -10px);
      -moz-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      transform: translate(0, -10px);
    }
    [data-balloon][data-balloon-pos='down-left']:hover:after,
    [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:after {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='down-left']:hover:before,
    [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:before {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='down-right']:after {
      right: 0;
      margin-top: 11px;
      top: 100%;
      -webkit-transform: translate(0, -10px);
      -moz-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      transform: translate(0, -10px);
    }
    [data-balloon][data-balloon-pos='down-right']:before {
      background: no-repeat
        url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
      background-size: 100% auto;
      width: 18px;
      height: 6px;
      right: 5px;
      margin-top: 5px;
      top: 100%;
      -webkit-transform: translate(0, -10px);
      -moz-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      transform: translate(0, -10px);
    }
    [data-balloon][data-balloon-pos='down-right']:hover:after,
    [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:after {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='down-right']:hover:before,
    [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:before {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    [data-balloon][data-balloon-pos='left']:after {
      margin-right: 11px;
      right: 100%;
      top: 50%;
      -webkit-transform: translate(10px, -50%);
      -moz-transform: translate(10px, -50%);
      -ms-transform: translate(10px, -50%);
      transform: translate(10px, -50%);
    }
    [data-balloon][data-balloon-pos='left']:before {
      background: no-repeat
        url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
      background-size: 100% auto;
      width: 6px;
      height: 18px;
      margin-right: 5px;
      right: 100%;
      top: 50%;
      -webkit-transform: translate(10px, -50%);
      -moz-transform: translate(10px, -50%);
      -ms-transform: translate(10px, -50%);
      transform: translate(10px, -50%);
    }
    [data-balloon][data-balloon-pos='left']:hover:after,
    [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
    [data-balloon][data-balloon-pos='left']:hover:before,
    [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
    [data-balloon][data-balloon-pos='right']:after {
      left: 100%;
      margin-left: 11px;
      top: 50%;
      -webkit-transform: translate(-10px, -50%);
      -moz-transform: translate(-10px, -50%);
      -ms-transform: translate(-10px, -50%);
      transform: translate(-10px, -50%);
    }
    [data-balloon][data-balloon-pos='right']:before {
      background: no-repeat
        url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
      background-size: 100% auto;
      width: 6px;
      height: 18px;
      left: 100%;
      margin-left: 5px;
      top: 50%;
      -webkit-transform: translate(-10px, -50%);
      -moz-transform: translate(-10px, -50%);
      -ms-transform: translate(-10px, -50%);
      transform: translate(-10px, -50%);
    }
    [data-balloon][data-balloon-pos='right']:hover:after,
    [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
    [data-balloon][data-balloon-pos='right']:hover:before,
    [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
    [data-balloon][data-balloon-length='small']:after {
      white-space: pre-line;
      width: 80px;
    }
    [data-balloon][data-balloon-length='medium']:after {
      white-space: pre-line;
      width: 150px;
    }
    [data-balloon][data-balloon-length='large']:after {
      white-space: pre-line;
      width: 260px;
    }
    [data-balloon][data-balloon-length='xlarge']:after {
      white-space: pre-line;
      width: 380px;
    }
    @media screen and (max-width: 768px) {
      [data-balloon][data-balloon-length='xlarge']:after {
        white-space: pre-line;
        width: 90vw;
      }
    }
    [data-balloon][data-balloon-length='fit']:after {
      white-space: pre-line;
      width: 100%;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .innerWrapper,
    .innerWrapper > .column,
    .row > main {
      width: calc(100vw - 30px) !important;
      max-width: none;
      flex: 0 0 100%;
    }
    .row > main {
      margin-left: auto;
      margin-right: auto;
    }
    .WidgetH50 .container,
    .WidgetH50 .container-fluid {
      height: auto;
    }
    .innerWrapper {
      justify-content: flex-end;
    }
    .row.card-title {
      .fa-caret-left,
      .fa-caret-down {
        float: right;
      }
    }
    .tree-container {
      height: initial !important;
    }

    .column-level-inner-wrapper {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  //MaintenancesComponent
  .table-maintenances {
    a {
      color: #000;
      text-decoration: none;

      &:hover {
        color: #e94e1b;
      }
    }

    .planned,
    .type {
      color: #777;
    }

    .status,
    .operation {
      display: block;
    }

    .operation {
      font-weight: 700;
      line-height: 1.6;
    }

    .period + .totalHours::before {
      content: ' / ';
    }

    .badge.status {
      display: inline-block;
      padding: 0;
      width: 7px;
      height: 0.6rem;
      line-height: 1rem;
      margin-right: 5px;
      margin-bottom: 0.1rem;
    }

    &.ReactTable {
      .rt-th,
      .rt-td {
        text-overflow: initial;
        overflow: visible;
        white-space: initial;
      }
    }
  }

  //MediaWidget
  .table-media {
    a {
      color: #000;
      text-decoration: none;

      &:hover {
        color: #e94e1b;
      }
    }

    .badge.status {
      display: inline-block;
      padding: 0;
      width: 7px;
      height: 0.6rem;
      line-height: 1rem;
      margin-right: 5px;
      margin-bottom: 0.1rem;
    }
  }

  #video-preview {
    min-height: 400px;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .WidgetSmartdoc {
    .wrapper {
      -webkit-overflow-scrolling: touch;
    }
  }
}



//SmartDocsComponent
.sidecontent {
  .ReactTable .rt-th, .ReactTable .rt-td {
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
  }

  .ReactTable .rt-table,
  .ReactTable .rt-tbody{
    overflow: visible !important;
  }

  .rt-td a {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }
}


