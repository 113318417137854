@charset "UTF-8";
.inplant-designer-fe .WidgetDesigner {
  display: flex;
  height: 100%;
  flex: 1; }
  .inplant-designer-fe .WidgetDesigner .breadcrumb {
    padding: 0.15rem 1rem; }
    .inplant-designer-fe .WidgetDesigner .breadcrumb > span + span::before {
      content: ' > ';
      display: inline-block;
      padding: 0 8px;
      color: #aaa; }
    .inplant-designer-fe .WidgetDesigner .breadcrumb span {
      cursor: pointer; }
      .inplant-designer-fe .WidgetDesigner .breadcrumb span:hover {
        opacity: 0.7; }
  .inplant-designer-fe .WidgetDesigner .cards {
    min-height: 100px; }
  .inplant-designer-fe .WidgetDesigner table a {
    cursor: pointer; }
  .inplant-designer-fe .WidgetDesigner .column {
    float: left;
    overflow-x: hidden;
    flex-shrink: 0;
    flex-grow: 1;
    height: 100%;
    transition: opacity 0.3s, width 0.3s; }
    .inplant-designer-fe .WidgetDesigner .column.to-hide {
      width: 0; }
  .inplant-designer-fe .WidgetDesigner .wrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end; }
  .inplant-designer-fe .WidgetDesigner .wrapper,
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    transition: all 0.3s; }
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    display: flex; }
  .inplant-designer-fe .WidgetDesigner .card-details h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold; }
  .inplant-designer-fe .WidgetDesigner .card-data h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold; }

.inplant-designer-fe .column-level {
  display: inline-flex;
  width: 150px;
  height: 84vh;
  overflow: hidden;
  transition: width 0.3s; }

.inplant-designer-fe .column-level.active {
  width: 550px; }

.inplant-designer-fe .column-level-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.inplant-designer-fe .column-level-inner-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;
  margin-left: auto; }

.inplant-designer-fe .card-level {
  width: 100%;
  min-height: 270px; }
  .inplant-designer-fe .card-level.has-status {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity; }
    .inplant-designer-fe .card-level.has-status:hover {
      opacity: 1; }
    .inplant-designer-fe .card-level.has-status::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: gray;
      width: 4px; }
    .inplant-designer-fe .card-level.has-status.in-use.has-pid, .inplant-designer-fe .card-level.has-status.in-use.card-level_ComponentGroup, .inplant-designer-fe .card-level.has-status.in-use.card-level_MacroArea {
      opacity: 1; }
      .inplant-designer-fe .card-level.has-status.in-use.has-pid::before, .inplant-designer-fe .card-level.has-status.in-use.card-level_ComponentGroup::before, .inplant-designer-fe .card-level.has-status.in-use.card-level_MacroArea::before {
        background-color: #18ba1c; }
  .inplant-designer-fe .card-level .node-has-qrcode {
    position: absolute;
    top: 5px;
    right: 8px;
    color: #555;
    font-size: 1.4rem; }
    .inplant-designer-fe .card-level .node-has-qrcode:hover {
      color: #212529; }

.inplant-designer-fe .column-level.active ~ .column-level {
  opacity: 0;
  visibility: hidden; }

.inplant-designer-fe .card-level {
  position: relative; }

.inplant-designer-fe .card-level .prev {
  width: 30px;
  height: 100%;
  text-align: center;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 10; }

.inplant-designer-fe .card-level .prev svg {
  width: 10px; }

.inplant-designer-fe .card-level {
  border: 1px solid #ddd; }

.inplant-designer-fe .card-inner {
  padding: 15px 35px 30px; }

.inplant-designer-fe .card-level .prev svg {
  opacity: 0.3;
  transition: opacity 0.3s, transform 0.3s; }

.inplant-designer-fe .column-level:not(:nth-child(1)) .card-level .prev svg {
  opacity: 0.3; }

.inplant-designer-fe .card-level.active .prev:hover svg {
  opacity: 1;
  transform: translateX(-5px); }

.inplant-designer-fe .card-level .prev path {
  stroke-width: 3px; }

.inplant-designer-fe .card-actions {
  margin-top: 25px;
  margin-bottom: 0;
  align-self: flex-end; }

.inplant-designer-fe .card-inner {
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-direction: column;
  /* flex-grow: 1; */ }

.inplant-designer-fe .card-level {
  display: flex;
  flex-direction: column; }

.inplant-designer-fe .card-details {
  flex-grow: 1; }
  .inplant-designer-fe .card-details .period + .totalHours::before {
    content: ' / '; }
  .inplant-designer-fe .card-details .value {
    float: right; }

.inplant-designer-fe .card-inner .list-group-item {
  padding: 0.2rem 0.5rem;
  background-color: transparent;
  font-size: 0.8rem; }

.inplant-designer-fe .card-inner .list-group {
  border-top: 1px solid #888;
  border-bottom: 1px solid #888; }

.inplant-designer-fe .card-inner span.label {
  font-weight: bold;
  display: inline-block;
  width: 30%; }

.inplant-designer-fe .card-level {
  border: 1px solid #ccc !important;
  margin-bottom: 8px;
  border-radius: 3px;
  background: #dddddd; }

.inplant-designer-fe .card-actions a + a {
  margin-left: 10px; }

.inplant-designer-fe .card-inner h4 {
  padding-bottom: 5px;
  font-size: 1.1rem; }

.inplant-designer-fe .column-level .column-level-inner-wrapper {
  opacity: 0.5;
  pointer-events: none; }

.inplant-designer-fe .column-level .card-title,
.inplant-designer-fe .column-level .card-details,
.inplant-designer-fe .column-level .card-actions {
  display: none; }

.inplant-designer-fe .column-level .card-title p {
  margin-bottom: 0 !important; }

.inplant-designer-fe .card-title p {
  margin-bottom: 0 !important;
  font-size: 0.85rem; }

.inplant-designer-fe .column-level.active .column-level-inner-wrapper {
  opacity: 1;
  pointer-events: auto; }

.inplant-designer-fe .column-level.active .card-title,
.inplant-designer-fe .column-level.active .card-details,
.inplant-designer-fe .column-level.active .card-actions {
  display: flex; }

.inplant-designer-fe .column-level-wrapper .column-title {
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin-bottom: 20px;
  margin-left: 33px;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.3s; }

.inplant-designer-fe .column-level.active .column-level-wrapper .column-title {
  font-size: 1.4rem;
  line-height: 1.4rem;
  opacity: 1; }

.inplant-designer-fe .card-level.active,
.inplant-designer-fe .card-level:hover {
  background: #e9ecef; }

.inplant-designer-fe .column-level .prev,
.inplant-designer-fe .column-level .next {
  opacity: 0; }

.inplant-designer-fe .column-level.active .prev,
.inplant-designer-fe .column-level.active .next {
  opacity: 0.5; }

.inplant-designer-fe .column-level .column-level-wrapper h3::after {
  content: '>';
  display: inline-block;
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 10px; }

.inplant-designer-fe .column-level.active .column-level-wrapper h3::after {
  display: none; }

.inplant-designer-fe .column-level.active .card-level:hover {
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.2); }

.inplant-designer-fe .WidgetTreeNavigation {
  flex-direction: column; }

.inplant-designer-fe .WidgetTreeNavigation .column-wrapper {
  flex-direction: row; }

.inplant-designer-fe .WidgetTreeNavigation .breadcrumb {
  width: 100%;
  display: inline-flex;
  height: 24px;
  line-height: 24px;
  margin: 15px 5px 30px; }

.inplant-designer-fe .breadcrumb .active + .active::before {
  content: '>';
  display: inline-block;
  width: 40px;
  height: 14px;
  text-align: center; }

.inplant-designer-fe .breadcrumb .active {
  cursor: pointer; }

.inplant-designer-fe .column-wrapper {
  overflow-x: hidden; }

.inplant-designer-fe .card-data {
  opacity: 0;
  transition: opacity 0.3s linear 0.3s, height 0.3s, max-height 0.3s ease-out;
  max-height: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  overflow: hidden; }

.inplant-designer-fe .card-data.active {
  max-height: 900px;
  opacity: 1; }

.inplant-designer-fe .card-data.active.data-maintenance {
  height: 350px; }

.inplant-designer-fe .card-data.active .card-data-inner {
  opacity: 1; }

.inplant-designer-fe .card-data.active.loading .card-data-inner {
  opacity: 0 !important; }

.inplant-designer-fe .card-data-inner {
  padding: 30px; }

.inplant-designer-fe .card-data-inner {
  transition: opacity 0.3s; }

.inplant-designer-fe .card-data-inner a {
  color: #555; }

.inplant-designer-fe .card-data-inner .small-data {
  font-size: 0.85rem; }

.inplant-designer-fe .hidden {
  display: none; }

.inplant-designer-fe .card-data td {
  line-height: 1;
  font-size: 0.9rem; }

.inplant-designer-fe .table-sm th,
.inplant-designer-fe .table-sm td {
  padding: 0.5rem 0.3rem; }

.inplant-designer-fe .card-data a {
  font-weight: bold; }

.inplant-designer-fe .card-actions a {
  width: 40px; }

.inplant-designer-fe .card-level:last-child {
  margin-bottom: 140px; }

.inplant-designer-fe .designer-node-actions {
  list-style: none;
  margin: 0;
  position: absolute;
  border-top: 1px solid #777;
  border-left: 1px solid #777;
  padding: 5px 4px 3px 5px;
  bottom: 0;
  right: 0;
  border-top-left-radius: 4px;
  background-color: #888; }
  .inplant-designer-fe .designer-node-actions li {
    display: inline-block;
    width: 20px;
    text-align: center;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer; }
    .inplant-designer-fe .designer-node-actions li path {
      fill: white; }
    .inplant-designer-fe .designer-node-actions li + li {
      margin-left: 7px; }
    .inplant-designer-fe .designer-node-actions li::after {
      content: '';
      display: inline-block; }
    .inplant-designer-fe .designer-node-actions li:hover path {
      fill: #7adc7a; }
  .inplant-designer-fe .designer-node-actions .action-delete-node:hover path {
    fill: #ff7575; }
  .inplant-designer-fe .designer-node-actions a {
    width: 100%;
    display: inline-block; }

.inplant-designer-fe .add-sibling-node {
  width: calc(100% - 30px);
  background-color: #c7c7c7;
  margin-top: 15px;
  border: 1px solid #aaa; }
  .inplant-designer-fe .add-sibling-node:hover {
    background-color: #a8e2ba; }
  .inplant-designer-fe .add-sibling-node:focus, .inplant-designer-fe .add-sibling-node:active {
    background-color: #7ea; }
  .inplant-designer-fe .add-sibling-node path {
    fill: #333; }

.inplant-designer-fe .add-node form,
.inplant-designer-fe .edit-node form {
  margin-top: 20px; }
  .inplant-designer-fe .add-node form .form-check,
  .inplant-designer-fe .edit-node form .form-check {
    padding-left: 2.25rem; }

.inplant-designer-fe .add-node .form-check input[type="checkbox"][readonly],
.inplant-designer-fe .edit-node .form-check input[type="checkbox"][readonly] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0; }

.inplant-designer-fe .add-node .form-check input[type="checkbox"][readonly]:checked ~ .form-check-label,
.inplant-designer-fe .edit-node .form-check input[type="checkbox"][readonly]:checked ~ .form-check-label {
  opacity: 1; }
  .inplant-designer-fe .add-node .form-check input[type="checkbox"][readonly]:checked ~ .form-check-label::before,
  .inplant-designer-fe .edit-node .form-check input[type="checkbox"][readonly]:checked ~ .form-check-label::before {
    color: green;
    opacity: 1; }

.inplant-designer-fe .add-node .form-check input[type="checkbox"][readonly] ~ .form-check-label,
.inplant-designer-fe .edit-node .form-check input[type="checkbox"][readonly] ~ .form-check-label {
  opacity: 0.5;
  padding-left: 0.5rem; }
  .inplant-designer-fe .add-node .form-check input[type="checkbox"][readonly] ~ .form-check-label::before,
  .inplant-designer-fe .edit-node .form-check input[type="checkbox"][readonly] ~ .form-check-label::before {
    position: absolute;
    top: -0.6rem;
    left: -0.2rem;
    content: '●';
    font-size: 2.5rem;
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    color: red;
    opacity: 0.5; }

.inplant-designer-fe .top-actions {
  position: absolute;
  top: 1rem;
  right: 1rem; }

.inplant-designer-fe .column-level-inner-wrapper > div {
  padding-bottom: 100px;
  height: auto !important; }

.inplant-designer-fe .column-actions {
  max-width: 430px;
  display: flex; }

.inplant-designer-fe .column-actions a {
  background-color: #c7c7c7;
  margin-top: 15px;
  border: 1px solid #aaa;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  transition: all 0.3s !important; }

.inplant-designer-fe .column-actions a + a {
  margin-left: 8px; }

.inplant-designer-fe .column-actions.multiple a {
  flex-grow: 2 !important;
  flex-shrink: 2 !important; }

.inplant-designer-fe .list-folders,
.inplant-designer-fe .list-files {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s; }

.inplant-designer-fe .modal-body > .list-group > .list-folders,
.inplant-designer-fe .modal-body > .list-group > .list-folders > .list-folder > .list-group > .list-files {
  max-height: 9999em; }

.inplant-designer-fe .modal-body {
  max-height: 70vh;
  overflow-y: auto; }

.inplant-designer-fe .list-folder.open > .list-group > .list-folders,
.inplant-designer-fe .list-folder.open > .list-group > .list-files {
  max-height: 9999em; }

.inplant-designer-fe .list-folder .svg-inline--fa {
  font-size: 1.2rem;
  margin-right: 15px; }

.inplant-designer-fe .list-file-single {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer; }

.inplant-designer-fe .list-file-single:hover {
  background-color: #eee; }

.inplant-designer-fe .list-folder {
  cursor: pointer; }

.inplant-designer-fe .list-file-single.selected {
  background-color: #ffcccc; }

.inplant-designer-fe .list-file-single .file-name {
  justify-self: flex-start;
  flex-grow: 1; }

.inplant-designer-fe .list-file-single .file-size,
.inplant-designer-fe .list-folder .file-data {
  justify-self: flex-end;
  text-align: right; }

.inplant-designer-fe .list-file-single .file-data {
  margin-left: 20px; }

.inplant-designer-fe .list-file-single .file-data {
  order: 3;
  width: 20%; }

.inplant-designer-fe .list-file-single .file-size {
  order: 2;
  width: 20%; }

.inplant-designer-fe .list-group-item {
  padding-right: 0; }

.inplant-designer-fe .form-attachments li {
  padding: 5px 10px !important;
  justify-content: space-between;
  display: inline-flex; }
  .inplant-designer-fe .form-attachments li .fa-language {
    position: absolute;
    top: 10px;
    left: -16px;
    z-index: 10;
    transform: rotateZ(90deg);
    color: var(--brand-secondary);
    opacity: 0.7; }
  .inplant-designer-fe .form-attachments li:hover .fa-language {
    opacity: 1; }
  .inplant-designer-fe .form-attachments li.list-group-item {
    flex-wrap: wrap;
    padding-bottom: 20px !important;
    background-color: #fff; }
    .inplant-designer-fe .form-attachments li.list-group-item + li {
      border-top: 1px solid #ccc; }
    .inplant-designer-fe .form-attachments li.list-group-item:hover, .inplant-designer-fe .form-attachments li.list-group-item:focus {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      z-index: 10; }
    .inplant-designer-fe .form-attachments li.list-group-item .file-version {
      flex-basis: 30%; }
      .inplant-designer-fe .form-attachments li.list-group-item .file-version input {
        padding-left: 0;
        margin: 0 auto;
        border: none;
        border-bottom: 2px dashed #ccc;
        text-align: left;
        cursor: pointer; }
        .inplant-designer-fe .form-attachments li.list-group-item .file-version input:focus {
          border: 1px solid #ced4da;
          border-radius: 0.25rem; }
        .inplant-designer-fe .form-attachments li.list-group-item .file-version input::placeholder {
          color: #aaa;
          font-style: italic; }
    .inplant-designer-fe .form-attachments li.list-group-item .file-name {
      font-size: 1rem;
      flex-basis: 70%;
      border-bottom: 1px solid #eee; }
    .inplant-designer-fe .form-attachments li.list-group-item .file-type {
      flex-basis: 30%;
      text-align: right;
      border-bottom: 1px solid #eee; }

.inplant-designer-fe .form-attachments .fa-times {
  cursor: pointer; }

.inplant-designer-fe .form-attachments .file-name {
  font-size: 0.9rem;
  padding-top: 2px; }

.inplant-designer-fe .form-attachments .file-langs {
  margin-left: auto;
  margin-right: 25px; }

.inplant-designer-fe .form-attachments .file-list-action,
.inplant-designer-fe .form-media .media-list-action {
  margin-left: 7px;
  margin-right: 7px;
  color: #444; }

.inplant-designer-fe .language-select {
  float: right;
  position: relative; }
  .inplant-designer-fe .language-select .fa-globe {
    font-size: 16px;
    cursor: pointer;
    color: #555; }

.inplant-designer-fe .language-select ul {
  margin: 0;
  list-style: none;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 3px 8px;
  position: absolute;
  background-color: #fff;
  right: 0;
  display: none; }

.inplant-designer-fe .copyable {
  cursor: pointer; }

.inplant-designer-fe .input-translations {
  float: right; }
  .inplant-designer-fe .input-translations span {
    font-size: 0.75rem;
    color: var(--primary);
    cursor: pointer;
    text-transform: lowercase; }
    .inplant-designer-fe .input-translations span + span {
      margin-left: 2px; }
      .inplant-designer-fe .input-translations span + span::before {
        content: ' | ';
        margin-right: 2px;
        color: #777; }

.inplant-designer-fe .flag-icon {
  width: 1rem;
  cursor: pointer; }

.inplant-designer-fe .flag-icon:hover {
  opacity: 0.7; }

.inplant-designer-fe .language-select:hover ul {
  display: block; }

.inplant-designer-fe .language-select .do-select {
  font-size: 0.75rem; }

.inplant-designer-fe .file-langs .file-lang-single + .file-lang-single {
  margin-left: 8px; }

.inplant-designer-fe .file-lang-single.has-translations {
  opacity: 0.4; }

.inplant-designer-fe .file-lang-single.has-translations.enabled {
  opacity: 1; }

.inplant-designer-fe .language-select .fa-caret-left {
  line-height: 1rem;
  color: #555;
  width: 10px;
  height: 13px;
  transition: transform 0.3s; }

.inplant-designer-fe .language-select:hover .fa-caret-left {
  transform: rotateZ(-90deg); }

.inplant-designer-fe .language-select.language-selected li span {
  opacity: 0.4; }

.inplant-designer-fe .language-select.language-selected li.enabled span {
  opacity: 1; }

.inplant-designer-fe .file-list-action,
.inplant-designer-fe .media-list-action {
  cursor: pointer; }

.inplant-designer-fe .form-attachments .file-name {
  width: 50%;
  position: relative; }
  .inplant-designer-fe .form-attachments .file-name::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, white 100%); }
  .inplant-designer-fe .form-attachments .file-name:hover {
    cursor: pointer; }

.inplant-designer-fe .form-attachments .file-langs {
  margin-left: auto;
  margin-right: 25px; }

.inplant-designer-fe .form-attachments .file-list-action,
.inplant-designer-fe .form-media .media-list-action {
  margin-left: 15px;
  color: #588; }

.inplant-designer-fe .form-attachments .file-list-action .fa-times,
.inplant-designer-fe .form-media .media-list-action .fa-times,
.inplant-designer-fe .metric-actions .fa-times {
  color: #f55; }

.inplant-designer-fe .metrics-list li {
  cursor: ns-resize; }
  .inplant-designer-fe .metrics-list li.active {
    background-color: var(--info) !important;
    cursor: default; }

.inplant-designer-fe .metrics-list.ordering-disabled li {
  cursor: default; }

.inplant-designer-fe .metrics-list .metric-address {
  float: right;
  font-size: 0.8rem;
  color: #555; }

.inplant-designer-fe .metrics-list .metric-actions {
  text-align: right;
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  min-width: 80px; }
  .inplant-designer-fe .metrics-list .metric-actions .metric-action {
    font-size: 16px;
    cursor: pointer; }
    .inplant-designer-fe .metrics-list .metric-actions .metric-action svg {
      color: #555; }
    .inplant-designer-fe .metrics-list .metric-actions .metric-action + .metric-action {
      margin-left: 10px; }

.inplant-designer-fe .form-metrics ul {
  list-style: none;
  padding: 0;
  border: 1px solid #ccc; }

.inplant-designer-fe .form-metrics li {
  padding: 4px 8px 3px;
  font-size: 0.9rem; }
  .inplant-designer-fe .form-metrics li + li {
    border-top: 1px solid #ccc;
    margin-top: 0; }
  .inplant-designer-fe .form-metrics li:nth-child(2n) {
    background-color: #eee; }

.inplant-designer-fe .form-metrics-global ul {
  margin-top: 25px; }

.inplant-designer-fe .form-metrics-global .section-body.open {
  overflow-y: auto;
  max-height: 70vh;
  padding-bottom: 48px;
  position: relative; }

@media screen and (max-width: 768px) {
  .inplant-designer-fe {
    /* MOBILE TEST */ }
    .inplant-designer-fe .tree-container {
      width: 100%; }
    .inplant-designer-fe .column-level,
    .inplant-designer-fe .column-level-wrapper,
    .inplant-designer-fe .column-level-inner-wrapper {
      overflow: visible; }
    .inplant-designer-fe .column-level.active {
      max-width: 100%; }
    .inplant-designer-fe .column-level-wrapper .column-title {
      margin-left: 0;
      display: none; }
    .inplant-designer-fe .column-level-inner-wrapper {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0; }
      .inplant-designer-fe .column-level-inner-wrapper > .container-fluid {
        height: auto !important;
        padding-left: 0;
        padding-right: 0; }
    .inplant-designer-fe .card-title p {
      margin-bottom: 0; }
    .inplant-designer-fe .card-inner {
      padding: 15px 20px; }
      .inplant-designer-fe .card-inner h4 {
        font-size: 1.25rem; }
    .inplant-designer-fe .card-actions {
      justify-content: space-between; }
      .inplant-designer-fe .card-actions a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 3px; }
    .inplant-designer-fe .breadcrumb {
      padding: 0.15rem 0rem; }
      .inplant-designer-fe .breadcrumb > span + span::before {
        content: ' > ';
        display: inline-block;
        padding: 0 8px;
        color: #aaa; }
      .inplant-designer-fe .breadcrumb span {
        cursor: pointer; }
        .inplant-designer-fe .breadcrumb span:hover {
          opacity: 0.7; }
    .inplant-designer-fe .card-actions > div {
      text-align: center !important; }
    .inplant-designer-fe .card-level .prev {
      width: 18px; }
    .inplant-designer-fe .WidgetDesigner .breadcrumb span {
      width: 100%;
      padding: 2px 0; }
      .inplant-designer-fe .WidgetDesigner .breadcrumb span + span {
        border-top: 1px solid #aaa; }
        .inplant-designer-fe .WidgetDesigner .breadcrumb span + span::before {
          display: none; }
    .inplant-designer-fe .card-title .svg-inline--fa {
      display: none !important; }
    .inplant-designer-fe .WidgetDesigner .wrapper,
    .inplant-designer-fe .WidgetDesigner .cards {
      width: 100%; }
    .inplant-designer-fe .WidgetDesigner .innerWrapper {
      justify-content: flex-end; } }

.inplant-designer-fe .section-body {
  overflow: hidden;
  max-height: 0;
  border: 1px solid #ccc;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 15px 0;
  border-top: none; }
  .inplant-designer-fe .section-body.open {
    max-height: 50vh;
    overflow-y: auto; }
  .inplant-designer-fe .section-body .section-wrapper {
    padding-top: 15px; }
    .inplant-designer-fe .section-body .section-wrapper label {
      margin-bottom: 0;
      font-size: 0.9rem; }

.inplant-designer-fe .section-header {
  background-color: #eee;
  padding: 6px 8px 3px 25px;
  cursor: pointer;
  font-size: 0.9rem !important;
  border-radius: 3px;
  border: 1px solid #ccc;
  border-bottom-color: transparent;
  text-transform: uppercase;
  line-height: 1rem;
  position: relative; }
  .inplant-designer-fe .section-header .svg-inline--fa {
    position: absolute;
    left: 8px; }

.inplant-designer-fe .section-actions {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  height: 32px; }

.inplant-designer-fe .form-actions {
  margin-top: 40px !important; }

.inplant-designer-fe .metric-form-visible > .form-actions .btn {
  pointer-events: none;
  opacity: 0.3; }

@media screen and (min-width: 1025px) {
  .inplant-designer-fe .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.9em;
    background-color: transparent;
    margin-left: 15px;
    margin-top: 5px; }
  .inplant-designer-fe .breadcrumb span {
    color: #e94e1b;
    text-decoration: none;
    background-color: transparent; }
  .inplant-designer-fe .breadcrumb span:last-child {
    color: #6c757d; }
  .inplant-designer-fe .WidgetDesigner .breadcrumb > span + span::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '/'; }
  .inplant-designer-fe .main-title {
    margin-left: 30px;
    margin-top: 15px; }
  .inplant-designer-fe .main-title h1 {
    font-size: 1.5rem;
    letter-spacing: -0.025em;
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
    color: inherit; }
  .inplant-designer-fe .card-level .prev span {
    margin: auto; }
  .inplant-designer-fe .card-level .prev {
    display: flex;
    justify-content: center; }
  .inplant-designer-fe .WidgetDesigner .column {
    max-width: 85px; }
  .inplant-designer-fe .WidgetDesigner .column:last-child {
    width: 500px;
    max-width: 100%; }
  .inplant-designer-fe .column-level-inner-wrapper {
    overflow-x: hidden; }
  .inplant-designer-fe .WidgetDesigner .column {
    position: relative; }
    .inplant-designer-fe .WidgetDesigner .column:first-child {
      margin-left: 10px; }
  .inplant-designer-fe .WidgetDesigner .wrapper {
    justify-content: flex-end; }
  .inplant-designer-fe .WidgetDesigner .column::after {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: 100%;
    background-color: #dddddd;
    content: '';
    display: block;
    margin-left: 20px;
    z-index: 100;
    border-radius: 3px;
    border: 1px solid #ccc; }
  .inplant-designer-fe .WidgetDesigner .column:last-child::after {
    display: none; }
  .inplant-designer-fe .column .column-title {
    transform: rotate(90deg);
    opacity: 1;
    margin: 0;
    position: absolute;
    top: 30px;
    left: 65px;
    width: 250px;
    transform-origin: top left;
    z-index: 200;
    cursor: pointer; }
  .inplant-designer-fe .column:last-child .column-title {
    display: none; }
  .inplant-designer-fe .WidgetDesigner .wrapper,
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    min-width: 48vw; }
  .inplant-designer-fe .sidecontent {
    width: 45vw;
    float: left;
    margin-top: 82px;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 1px solid #eee;
    overflow: hidden; }
    .inplant-designer-fe .sidecontent .vertical-wrapper {
      max-height: calc(100% - 40px);
      overflow-y: auto;
      overflow-x: hidden; }
  .inplant-designer-fe .card-data.active {
    max-height: 0;
    opacity: 0; }
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    flex-grow: 1;
    flex-shrink: 0;
    width: auto !important; }
  .inplant-designer-fe .WidgetDesigner .wrapper {
    justify-content: flex-end; }
  .inplant-designer-fe .innerWrapper.animating .column:last-child {
    width: 0; }
  .inplant-designer-fe .column:last-child .column-title {
    display: block;
    transform: none;
    position: relative;
    top: auto;
    left: 35px;
    font-size: 1.7rem;
    text-transform: none;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #eee;
    width: calc(100% - 50px);
    text-align: left; }
  .inplant-designer-fe .col-1-0 {
    margin-left: 0 !important; }
  .inplant-designer-fe .sidecontent h3 {
    margin: 0;
    text-align: left;
    font-size: 1.5rem;
    margin-top: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px; }
  .inplant-designer-fe .sidecontent h4 {
    margin-top: 15px;
    font-size: 1.3rem; }
  .inplant-designer-fe .cards.has-media .card-title {
    cursor: pointer;
    flex-shrink: 0; }
  .inplant-designer-fe .cards.no-media .card-details,
  .inplant-designer-fe .cards.no-media .card-actions {
    display: none; }
  .inplant-designer-fe .cards.no-media .card-title {
    margin-bottom: 0; }
    .inplant-designer-fe .cards.no-media .card-title h4 {
      margin-bottom: 0;
      padding-bottom: 0; }
  .inplant-designer-fe .WidgetDesigner .cards,
  .inplant-designer-fe .card-inner {
    min-height: 60px; }
  .inplant-designer-fe .WidgetDesigner .cards.is-collapsed {
    max-height: 100px;
    overflow-y: hidden; }
    .inplant-designer-fe .WidgetDesigner .cards.is-collapsed .card-details,
    .inplant-designer-fe .WidgetDesigner .cards.is-collapsed .card-actions {
      opacity: 0; }
  .inplant-designer-fe .card-title .svg-inline--fa {
    position: absolute;
    top: 25px;
    right: 0;
    width: 18px;
    height: 18px;
    color: #444;
    transition: transform 0.3s; }
  .inplant-designer-fe .cards.has-media .card-title .svg-inline--fa {
    transform: rotate(0); }
  .inplant-designer-fe .cards.has-media.is-collapsed .card-title .svg-inline--fa {
    transform: rotate(-90deg); } }

@media screen and (min-width: 1440px) {
  .inplant-designer-fe .WidgetDesigner .cards,
  .inplant-designer-fe .add-sibling-node {
    max-width: 430px; } }

.inplant-designer-fe .tooltips {
  position: relative; }
  .inplant-designer-fe .tooltips::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(0);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
    z-index: 100;
    transition: opacity 0.3s;
    opacity: 0; }
  .inplant-designer-fe .tooltips::after {
    content: attr(data-title);
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translateX(0) translateY(-100%);
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    color: #fff;
    padding: 4px 2px;
    font-size: 12px;
    min-width: 100px;
    border-radius: 5px;
    pointer-events: none;
    transition: opacity 0.3s;
    opacity: 0; }
  .inplant-designer-fe .tooltips:hover::before, .inplant-designer-fe .tooltips:hover::after {
    opacity: 1; }

.inplant-designer-fe .overlay-focuser {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  pointer-events: none; }

.inplant-designer-fe .sidecontent {
  transition: box-shadow 0.3s; }

.inplant-designer-fe.sidecontent-open .overlay-focuser {
  opacity: 0.25; }

.inplant-designer-fe.sidecontent-open .sidecontent {
  z-index: 10000;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }

.inplant-designer-fe.sidecontent-open .top-actions .btn {
  pointer-events: none;
  opacity: 0.3; }

.inplant-designer-fe.sidecontent-open .card-level.active {
  background-color: #fff;
  z-index: 10; }
  .inplant-designer-fe.sidecontent-open .card-level.active .designer-node-actions {
    background-color: var(--brand-secondary); }

.inplant-designer-fe .form-group.relation-required input {
  box-shadow: 0 0 6px rgba(200, 40, 40, 0);
  animation: relationRequiredAnimation 1s;
  animation-iteration-count: 2; }

@keyframes relationRequiredAnimation {
  0% {
    box-shadow: 0 0 6px rgba(200, 40, 40, 0); }
  50% {
    box-shadow: 0 0 6px rgba(200, 40, 40, 0.8); }
  100% {
    box-shadow: 0 0 6px rgba(200, 40, 40, 0); } }

.metric-label {
  cursor: pointer; }

.preview-data .value {
  float: right;
  text-align: right; }

.preview-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px; }

.metric-order-helper {
  background-color: #fff;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
  padding: 4px 8px 3px;
  font-size: 0.9rem;
  list-style: none; }
  .metric-order-helper .metric-address {
    float: right;
    font-size: 0.8rem;
    color: #555; }
  .metric-order-helper .metric-actions {
    display: none; }

#preview-list-video {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  height: 80px;
  z-index: 10; }
  #preview-list-video img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }

.list-group-video {
  margin-bottom: 40px !important; }
  .list-group-video .list-group-item {
    position: relative;
    padding: 5px 10px;
    justify-content: space-between;
    display: inline-flex; }
